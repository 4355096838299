import React, { Fragment, useRef, useEffect, useState } from "react";

const Loader = props => {
	const loader = useRef(null);
	const [visibility, setVisibility] = useState(false);
	useEffect(() => {
		if (loader && loader.current && loader.current.parentNode) {
			setVisibility(true);
			loader.current.parentNode.style.position = "relative";
		}
	}, [props.logoLoader]);
	return (
		<Fragment>
			{props.logoLoader ? (
				<div
					ref={loader}
					className={`logoLoader ${props.className || ""} ${props.backdrop ? "backdrop" : ""} ${
						props.container ? "container" : ""
					} ${visibility ? "visible" : ""}`}
					style={props.style}>
					<div
						className="loader"
						style={
							props.width
								? {
										width: props.width,
										height: props.width + props.width / 2,
										...(props.marginLeft && { marginLeft: props.marginLeft })
								  }
								: null
						}>
						<div className="top"></div>
						<div className="rot"></div>
					</div>
				</div>
			) : (
				<span
					id="Loader"
					className={`${props.className || ""} ${props.static ? "static" : " "} ${
						props.inline ? "inline" : " "
					} ${props.skell ? "skell" : " "}`}
					style={{
						...props.style,
						height: props.skell ? props.skell : "auto"
					}}>
					<span className="inner">
						<svg fill="none" viewBox="0 0 100 100" id="zuy" {...props}>
							<circle
								cx="50"
								cy="50"
								r="45"
								stroke="url(#prefix__paint0_linear)"
								strokeWidth={5}
							/>
							<defs>
								<linearGradient
									id="prefix__paint0_linear"
									x1={25}
									y1={25}
									x2={25}
									y2={50}
									gradientUnits="userSpaceOnUse">
									<stop stopColor="#4FB2F6" />
									<stop offset={1} stopColor="#75C163" />
								</linearGradient>
							</defs>
						</svg>
					</span>
				</span>
			)}
		</Fragment>
	);
};

export default Loader;
